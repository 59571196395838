<template>
    <div class="index">
        <z-header :imgShowPro="imgShow" :curActive="4"></z-header>
        <router-view/>
	</div>
</template>
<script>
import zHeader from "../../components/header.vue"

export default {
    components:{zHeader},
    data(){
        return{
            imgShow:true,
            screenState:false
        }
    },
    created(){
        // 初始化获取浏览器窗口大小
        let screenWidth = document.body.clientWidth;
        // 根据浏览器窗口大小判断是否显示页面内的部分内容
        if(screenWidth<1200){
            this.screenState=false;
        }else{
            this.screenState=true;
        }
    }
}
</script>
<style scoped>
    .index .typeArea{
        display: flex;
        flex-direction: column;
        padding-bottom: 100px;
    }
    
    @media only screen and (max-width:1199px){
        .index .typeArea{
            padding-bottom: 0;
        }
    }
</style>